import {Box, FormControl, MenuItem, Select} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedBranch} from "../../store/slices/navSlice";
import SatelliteAltOutlinedIcon from '@mui/icons-material/SatelliteAltOutlined';

const defaultStyle = {}

function BranchSelector({mainOnly = false, selectedBranch, onBranchSelected, styles = defaultStyle}) {
    const selectedGroup = useSelector((state) => state.data.selectedGroup);
    const groups = useSelector((state) => state.data.groups);
    const [branches, setBranches] = useState([]);
    const dispatch = useDispatch();

    const handleBranchChange = (event) => {
        const selectedBranchData = branches.find((branch) => branch.code === event.target.value);
        onBranchSelected(selectedBranchData);
    };

    useEffect(() => {
        const selectedGroupData = groups.find((group) => group.id === selectedGroup);

        if (selectedGroupData) {
            const branches = JSON.parse(selectedGroupData.branches);

            const remappedBranches = Object.entries(branches).map(([key, value]) => ({
                code: key ?? '',
                id: value?.id ?? '',
                branch_name: value?.name ?? 'no branches assigned',
                main_branch_id: value?.main_branch_id,
                type: value?.type,
            }));

            const filtered = mainOnly
                ? remappedBranches.filter((branch) => branch.type === 'Main')
                : remappedBranches;

            setBranches(filtered);
            //onBranchSelected(filtered[0]);
        }
    }, [selectedGroup, groups, mainOnly, onBranchSelected]);

    useEffect(() => {
        if (branches.length > 0) {
            let thisBranch = branches.find((branch) => branch.code === selectedBranch?.code);

            if (!thisBranch) {
                thisBranch = branches.find((branch) => branch.id === branch.main_branch_id);
            }
            if (!thisBranch) {
                thisBranch = branches[0];
            }

            if (selectedBranch?.code !== thisBranch.code) {
                //if( selectedBranch?.id === selectedBranch?.main_branch_id)
                    //dispatch(setSelectedBranch(thisBranch));
                onBranchSelected(thisBranch);
            }
        }
    }, [branches, selectedBranch?.code, dispatch, onBranchSelected]);

    if (branches.length === 0) return "Loading..";

    const selectedValue = selectedBranch?.code || "";

    if( selectedValue === "") return "Loading...";

    console.log("rendering BranchSelector", selectedBranch?.code, branches, selectedValue);

    return (
        <FormControl sx={styles}>
            {selectedBranch && branches && branches.length > 0 ? (
                <Select value={selectedBranch.code} onChange={handleBranchChange}>
                    {branches.map((branch) => (
                        <MenuItem key={branch.code} value={branch.code}>
                            <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                {`${branch.branch_name} ${branch.code}`}
                                {branch.type !== 'Main' && (
                                    <SatelliteAltOutlinedIcon style={{paddingLeft: "5px", color: '#888888'}} fontSize="small"/>
                                )}
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
            ) : null}
        </FormControl>
    );
}

export default BranchSelector;
